import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const BookSummary = ({ book }) => {
  return (
    <div className="card blue-grey darken-1 z-depth-1 book-summary">
      <div className="card-content white-text">
        <span className="card-title ">{book.bookTitle}</span>
        <span>
          {book.languages.map((lang, i) => {
            return (
              <Link
                to={"/booklets/" + book.id +"/" + book.zItems[i].id}
                key={book.id + "-" + i}
              >
                {/* {console.log(book.zItems[i].id)} */}
                <div className="black-text lang">{lang.display}</div>
              </Link>
            );
          })}
        </span>
        <br />
        <span> </span>
        <br />
        <span>
          Source:{" "}
          <a className="yellow-text" href={book.srcURL}>
            {book.srcName}
          </a>{" "}
        </span>
        <p className="grey-text">
          {moment(book.uploadedAt.toDate()).calendar()}
        </p>
      </div>
    </div>
  );
};

export default BookSummary;
