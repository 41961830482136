import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { animateScroll as scroll } from "react-scroll";

const BookletDetails = (props) => {
  const { chant, hasMeanings, meanings, auth } = props;
  if (!auth.uid) return <Redirect to="/signin" />;
  if (chant) {
    const verseLIs = chant.verses.map((d) => (
      <li
        key={d.num.toString()}
        className="blue-grey lighten-1"
        id={"section" + d.num.toString()}
      >
        <span className="flow-text white-text">{d.verse}</span>
      </li>
    ));
    const verseCARDs = chant.verses.map((d) => (
      <div
        key={d.num.toString()}
        className="card-panel slim blue-grey lighten-1"
        id={"section" + d.num.toString()}
      >
        <span className="flow-text white-text">{d.verse}</span>
        <br />
        <span className="yellow-text">
          {d.num} {hasMeanings ? meanings.verses[d.num - 1].verse : ""}
        </span>
      </div>
    ));

    const scrollOptions = {
      smooth: "linear",
      duration: 100,
    };
    function scrollToTop() {
      scroll.scrollToTop();
    }
    function scrollMoreUp() {
      scroll.scrollMore(-500, scrollOptions);
    }

    // Scroll down
    function scrollToBottom() {
      scroll.scrollToBottom();
    }
    function scrollMoreDown() {
      scroll.scrollMore(500, scrollOptions);
    }

    const compact = false;
    if (compact) {
      return (
        <div className="container section">
          <div className="fab-container">
            <div className="fab fab-icon-holder pink">
              <i className="fas fa-arrows-alt"></i>
            </div>

            <ul className="fab-options">
              <li>
                {/* <span className="fab-label">To Top</span> */}
                <div className="fab-icon-holder" onClick={scrollToTop}>
                  <i className="fas fa-angle-double-up"></i>
                </div>
              </li>
              <li>
                {/* <span className="fab-label">Up</span> */}
                <div className="fab-icon-holder" onClick={scrollMoreUp}>
                  <i className="fas fa-angle-up"></i>
                </div>
              </li>
              <li>
                {/* <span className="fab-label">Down</span> */}
                <div className="fab-icon-holder" onClick={scrollMoreDown}>
                  <i className="fas fa-angle-down"></i>
                </div>
              </li>
              <li>
                {/* <span className="fab-label">To Bottom</span> */}
                <div className="fab-icon-holder" onClick={scrollToBottom}>
                  <i className="fas fa-angle-double-down"></i>
                </div>
              </li>
            </ul>
          </div>

          <div className="card z-depth-0">
            <div className="card-content">
              <span className="card-title">{chant.title}</span>
              <ol className="blue-grey lighten-1">{verseLIs}</ol>
            </div>
            <div className="card-action grey lighten-4 grey-text">
              <div>
                Source: {chant.authorFirstName} {chant.authorLastName}
              </div>
              <div>{moment(new Date(chant.createdAt)).calendar()}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container section">
          <div className="fab-container">
            <div className="fab fab-icon-holder pink">
              <i className="fas fa-arrows-alt"></i>
            </div>

            <ul className="fab-options">
              <li>
                {/* <span className="fab-label">To Top</span> */}
                <div className="fab-icon-holder" onClick={scrollToTop}>
                  <i className="fas fa-angle-double-up"></i>
                </div>
              </li>
              <li>
                {/* <span className="fab-label">Up</span> */}
                <div className="fab-icon-holder" onClick={scrollMoreUp}>
                  <i className="fas fa-angle-up"></i>
                </div>
              </li>
              <li>
                {/* <span className="fab-label">Down</span> */}
                <div className="fab-icon-holder" onClick={scrollMoreDown}>
                  <i className="fas fa-angle-down"></i>
                </div>
              </li>
              <li>
                {/* <span className="fab-label">To Bottom</span> */}
                <div className="fab-icon-holder" onClick={scrollToBottom}>
                  <i className="fas fa-angle-double-down"></i>
                </div>
              </li>
            </ul>
          </div>

          <div className="card z-depth-1">
            <div className="card-content">
              <span className="card-title">{chant.title}</span>
              {verseCARDs}
            </div>
            <div className="card-action grey lighten-4 grey-text">
              <div>
                Source: {chant.authorFirstName} {chant.authorLastName}
              </div>
              <div>{moment(new Date(chant.createdAt)).calendar()}</div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="container center">
        <p>Loading chant...</p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  console.log(state);
  const id = ownProps.match.params.id;
  const book = ownProps.match.params.book;
  const booklets = state.firestore.data.booklets;
  const books = state.firestore.data.books;
  const chant = booklets ? booklets[id] : null;
  const hasMeanings = books[book].hasMeanings;
  const meanings = hasMeanings ? books[book].meanings : null;

  return {
    chant: chant,
    hasMeanings: hasMeanings,
    meanings: meanings,
    auth: state.firebase.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: "booklets" }, 
    { collection: "books" }
  ])
)(BookletDetails);
