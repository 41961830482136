import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Replace this with your own config details
var N_config = {
  apiKey: "AIzaSyCh2PpId_sIH0qBlPIstZgP9xcWDZwS1Ro",
  authDomain: "namah-v1.firebaseapp.com",
  databaseURL: "https://namah-v1.firebaseio.com",
  projectId: "namah-v1",
  storageBucket: "namah-v1.appspot.com",
  messagingSenderId: "270678596636",
  appId: "1:270678596636:web:978617fd316164d7fb13d9",
  measurementId: "G-CGFLYNYQ1J"
};
firebase.initializeApp(N_config);
firebase.firestore().settings({});

export default firebase 