import React, { Component } from "react";
import BookList from "../books/BookList";
import Notifications from "./Notifications";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

class Dashboard extends Component {
  render() {
    const { auth, books, notifications } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;
    
    return (
      <div className="dashboard container">
        <div className="row">
          <div className="col s12 m6">
            <BookList books={books} />
          </div>
          <div className="col s12 m6"> 
            <Notifications notifications={notifications} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    auth: state.firebase.auth,
    books: state.firestore.ordered.books,
    notifications: state.firestore.ordered.notifications,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: "books", orderBy: ["displaySeq", "asc"] },
    { collection: "notifications", limit: 3, orderBy: ["time", "desc"] },
  ])
)(Dashboard);
