import React from 'react'
import BookSummary from './BookSummary'

const BookList = ({books}) => {
  return (
    <div className="book-list section">
      { books && books.map(book => {
        return (
            <BookSummary book={book} key={book.id}/>
        )
      })}  
    </div>
  )
}

export default BookList
